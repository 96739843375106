import React from 'react';
import './Plans.css';
import { plansData } from '../../data/plansData';
import whiteTick from "../../assets/whiteTick.png";

function Plans() {
    return (
        <div className='plans-container'>
        <div className="blur plan-blur-1"></div>
        <div className="blur plan-blur-2"></div>
            <div className="programs-header" style={{ gap: '2rem' }}>
                <span className='stroke-text'>READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className='stroke-text'>NOW WITHUS</span>
            </div>
            {/* plans cards */}
            <div className="plans">
                {plansData.map((plan, i) =>
                (
                    <div className='plan' key={i}>
                        { plan.icon }
                        <span>{plan.name}</span>
                        <span>$ {plan.price}</span>

                        <div className="features">
                            {
                                plan.features.map((feature,i)=>(
                                    <div className='feature' key={i}>
                                        <img src={whiteTick} alt="" />
                                        <span >{feature}</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div>
                            <span>See more benefits &#8722;&#62; </span>
                        </div>
                        <button className="btn">Join now</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Plans
